@use '@cds/core/tokens/tokens.scss';

footer {
  padding: tokens.$cds-global-space-9 0;

  .vmware-weblogo {
    margin: tokens.$cds-global-layout-space-sm 0;
  }

  .copyright {
    margin-top: $clr_baselineRem_0_25;
  }
}
