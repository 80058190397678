@use '@cds/core/tokens/tokens.scss';

// Container style for comps
.header-demo {
  padding: $clr_baselineRem_6px;
  margin: $clr_baselineRem_0_667 0 0;
  background-color: solid transparent;

  header {
    a {
      &:hover {
        color: inherit;
      }
    }
  }
}

@media screen and (max-width: map-get($clr-grid-breakpoints, md)) {
  // Override for the comp demos
  .header-demo {
    padding: tokens.$cds-global-layout-space-sm tokens.$cds-global-space-9;
    .branding {
      padding: 0 tokens.$cds-global-layout-space-sm;
      min-width: $clr_baselineRem_100px;
    }
  }
  // Override for the sub-nav demo
  .subnav {
    .nav {
      .nav-item {
        &:nth-child(4) {
          // Removing this so it fits inside for demo purposes on mobile.
          display: none;
        }
      }
    }
  }

  .header-actions {
    &--overflow {
      width: $clr_baselineRem_165px;
      overflow: hidden;
      font-size: $clr_baselineRem_0_458;
    }
  }
}

.col-no-padding {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
